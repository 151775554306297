import React, {Fragment, FunctionComponent, useContext, useEffect, useState} from "react";
import {BrowserView} from "react-device-detect";

import "./scrollbar.scss";

import useCursorHoverHandlers from "../hooks/useCursorHoverHandlers";
import {CursorContext} from "./cursor";

const Scrollbar: FunctionComponent<{contentHeight:number}> = ({...props}) => {
    const cursor = useContext(CursorContext).cursor;
    const [scrollOffset, setScrollOffset] = useState(1);
    const [barHeight, setBarHeight] = useState(1);
    const [mouseCatch, setMouseCatch] = useState(0);

    const scrollbarRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onScroll = () => setScrollOffset(window.scrollY);
        const onResize = () => setBarHeight((scrollbarRef.current?.clientHeight || 1));

        window.removeEventListener('scroll', onScroll);
        window.removeEventListener('resize', onResize);

        window.addEventListener('scroll', onScroll, { passive: true });
        window.addEventListener('resize', onResize, { passive: true });

        onScroll();
        onResize();

        return () => {
            window.removeEventListener('scroll', onScroll);
            window.removeEventListener('resize', onResize);
        }
    }, []);

    let scrollRatio = barHeight / props.contentHeight;
    let scrollerHeight = (barHeight * scrollRatio) - 2;

    let showBar = scrollerHeight + 2 < barHeight;
    
    function calcScroll(force: boolean = false) {

        let lock = cursor.y <= (scrollOffset * scrollRatio) - 50 || cursor.y >= (scrollOffset * scrollRatio) + (barHeight * scrollRatio) + 50;
        let mc = mouseCatch;

        if (force)
        {
            mc = cursor.y - (scrollOffset * scrollRatio);
            mc = mc < 0 ? 0 : mc > barHeight * scrollRatio ? barHeight * scrollRatio : mc;
            setMouseCatch(mc);

            window.scrollTo({top: (cursor.y / scrollRatio) - (mc / scrollRatio), behavior: "smooth" })
        }
        else if (cursor.active && !lock)
        {
            window.scrollTo({top: (cursor.y / scrollRatio) - (mc / scrollRatio)})
        }
    }

    return (
        <BrowserView>
            <div ref={scrollbarRef} className={`scrollbar ${showBar ? "fadein" : "noshow"}`} onMouseMove={() => calcScroll(false)} onMouseDown={() => {calcScroll(true)} } {...useCursorHoverHandlers()}>
                <div className="bar"/>
                <div className="scroller" style={{height: scrollerHeight, top: scrollOffset * scrollRatio}}/>
            </div>
        </BrowserView>
    );
};

export default Scrollbar;