import React, {FunctionComponent, useEffect} from "react";

import "./cv.scss";
import {isMobile} from "react-device-detect";
import NavButton from "../components/nav-button";
import Link from "../components/link";

const CV: FunctionComponent = ({...props}) => {
    return (
        <div className="cv-wrapper">
            <div className={`${!isMobile ? "cv-container" : "cv-container-m"}`}>
                <NavButton to={'/'}>&lt; back</NavButton>

                <h1>html cv &mdash; jake postiglione</h1>

                <p>physics phd student at the <Link link="https://www.gc.cuny.edu/">cuny graduate center</Link></p>
                <p>visiting scientist at <Link link="https://www.amnh.org/">american museum of natural history</Link></p>
                <p>guest researcher at the <Link link="https://www.simonsfoundation.org/flatiron/">flatiron institute</Link></p>
                <p>advisors: <Link link="https://saavikford.wixsite.com/saavik">saavik ford</Link>, <Link link="https://www.bmcc.cuny.edu/faculty/desmond-mckernan/">barry mckernan</Link></p>
                <p><b>research interests:</b> multi-messenger astronomy, gravitational wave progenitors, active galactic nuclei</p>

                <hr/>
                <h2>education and accolades</h2>

                <p><b>phd in physics</b>, cuny graduate center, new york &mdash; in progress</p>
                <p><b>bs in applied computational physics</b>, cuny citytech, new york &mdash; completed 2022 (magna cum laude)</p>

                <p>physics tithe fellowship &mdash; cuny graduate center, 2025</p>
                <p>{"The Professor Daria Boudana Memorial Award".toLowerCase()} &mdash; cuny citytech, 2022 </p>
                <p>deans list &mdash; cuny citytech (2021, 2022) </p>

                <hr/>
                <h2>publications and contributing research</h2>
                <p><b>*</b> = <i>first author</i></p>

                <p>
                    <b>* {"Evolution of LISA Observables for Binary Black Holes Lensed by an SMBH".toLowerCase()}</b>
                    <br/> <br/>
                    2025-02, jake postiglione et al. &mdash; <Link link="https://arxiv.org/abs/2502.10591">arxiv</Link>, submitted to apj
                </p>

                <p>
                    <b>{"Searching for electromagnetic emission in an AGN from the gravitational wave binary black hole merger candidate S230922g".toLowerCase()}</b>
                    <br/> <br/>
                    2024-12, tomás cabrera et al. &mdash; <Link link="https://arxiv.org/abs/2407.10698">arxiv</Link>, <Link link="https://journals.aps.org/prd/abstract/10.1103/PhysRevD.110.123029">journal (prd)</Link>
                </p>

                <p>
                    <b>{"McFacts III: Compact binary mergers from AGN disks over an entire synthetic universe".toLowerCase()}</b>
                    <br/> <br/>
                    2024-10, vera delfavero et al. &mdash; <Link link="https://arxiv.org/abs/2410.18815">arxiv</Link>, submitted to apj
                </p>

                <p>
                    <b>{"McFACTS II: Mass Ratio--Effective Spin Relationship of Black Hole Mergers in the AGN Channel".toLowerCase()}</b>
                    <br/> <br/>
                    2024-11, harrison e. cook et al. &mdash; <Link link="https://arxiv.org/abs/2411.10590">arxiv</Link>, submitted to apj
                </p>

                <p>
                    <b>{"McFACTS I: Testing the LVK AGN channel with Monte Carlo For AGN Channel Testing & Simulation (McFACTS)".toLowerCase()}</b>
                    <br/> <br/>
                    2024-10, barry mckernan et al. &mdash; <Link link="https://arxiv.org/abs/2410.16515">arxiv</Link>, submitted to apj
                </p>

                <p>
                    <b>{"Enhancing Exoplanet Ephemerides by Leveraging Professional and Citizen Science Data: A Test Case with WASP-77 A b".toLowerCase()}</b>
                    <br/> <br/>
                    2024-06, federico r. noguer et al. &mdash; <Link link="https://arxiv.org/abs/2405.19615">arxiv</Link>, <Link link="https://iopscience.iop.org/article/10.1088/1538-3873/ad57f5">journal (asp)</Link>
                </p>

                <hr/>
                <h2>talks and poster presentations</h2>

                <p>
                    <b>{"Evolution of LISA observables for binary black holes lensed by an SMBH".toLowerCase()}</b>
                    <br/> <br/>
                    2025-01 &mdash; <i>{"Presented at the 245th meeting of the American Astronomical Society".toLowerCase()}</i>
                </p>

                <p>
                    <b>{"Evolution of LISA observables for binary black holes lensed by an SMBH".toLowerCase()}</b>
                    <br/> <br/>
                    2024-07 &mdash; <i>{"Presented at the 15th international lisa symposium".toLowerCase()}</i>
                </p>

                <p>
                    <b>{"An EXOTIC method to reduce follow-up observations of transiting exoplanets".toLowerCase()}</b>
                    <br/> <br/>
                    2023-01 &mdash; <i>{"Presented at nyc gotham fest, simons foundation, new york".toLowerCase()}</i>
                </p>

                <p>
                    <b>{"Smart Physics: Bouncing from the Board to the Screen".toLowerCase()}</b>
                    <br/> <br/>
                    2022-06 &mdash; <i>{"Presented at American Association of Physics Teachers Summer Meeting".toLowerCase()}</i>
                </p>

                <p>
                    <b>{"Smart Physics: A Path to Innovate Laboratory Physics Experiences".toLowerCase()}</b>
                    <br/> <br/>
                    2022-06 &mdash; <i>{"Presented at American Association of Physics Teachers Summer Meeting".toLowerCase()}</i>
                </p>

                <p>
                    <b>{"Developing Materials for Independent Learners: Machine Learning for Physics and Astronomy".toLowerCase()}</b>
                    <br/> <br/>
                    2021-11 &mdash; <i>{"Presented at CityTech’s Semi-Annual Student Academic Conference".toLowerCase()}</i>
                </p>

                <p>
                    <b>{"Exploring the Developments of Early Methods in Linear Algebra".toLowerCase()}</b>
                    <br/> <br/>
                    2021-05 &mdash; <i>{"Presented at the MAA Metropolitan New York Section Virtual Meeting".toLowerCase()}</i>
                </p>

                <p>
                    <b>{"Using Interactive Math Applications to Model Concepts in Linear Algebra".toLowerCase()}</b>
                    <br/> <br/>
                    2021-05 &mdash; <i>{"Presented at CityTech’s Semi-Annual Research Scholars Poster Presentation".toLowerCase()}</i>
                </p>

                <p>
                    <b>{"Exploring the Development of Early Methods in Linear Algebra".toLowerCase()}</b>
                    <br/> <br/>
                    2021-05 &mdash; <i>{"Presented at CityTech’s Semi-Annual Research Scholars Poster Presentation".toLowerCase()}</i>
                </p>

                <hr/>
                <h2>teaching experience</h2>
                <p>astronomy of stellar systems (lab component) &mdash; cuny lehman college (fall 2024, spring 2025)</p>
                <p>science thoughts and practices &mdash; american museum of natural history (spring 2024)</p>
                <p>staff physics tutor &mdash; cuny citytech (2022, 2023)</p>

                <hr/>

                last updated 2025, 02, 17
            </div>
        </div>
    );
}

export default CV;